





































import { Component, Prop, Vue } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";

@Component({
  components: {
    DimssaButton
  }
})
export default class Location extends Vue {
  mounted() {
    if (this.$store.state.user.location) {
      this.selected = [this.$store.state.user.location];
    }
  }
  get items() {
    return this.$store.state.user.locations;
  }
  headers: any[] = [
    {
      text: "Location",
      value: "description"
    }
  ];
  select() {
    this.$store.commit("user/location", this.selected[0]);
    this.$router.push({ path: "/" });
  }
  selected: any[] = [];
  search: string = "";
}
