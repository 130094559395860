import Vue from 'vue';

type EventBusEvents = {
  childLoaded: void; // Define event types and payloads if any
};

// Create a typed EventBus
export const EventBus = new Vue() as Vue & {
  $on<K extends keyof EventBusEvents>(event: K, callback: (payload: EventBusEvents[K]) => void): any;
  $emit<K extends keyof EventBusEvents>(event: K, payload?: EventBusEvents[K]): any;
};
