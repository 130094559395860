





























































import { Component, Vue } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import * as Models from "@gigalot/data-models";

@Component({
  components: {
    DimssaButton
  }
})
export default class Setup extends Vue {
  async kraals(push: boolean = true) {
    try {
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: Models.gql.queries.kraals,
          variables: { guid: this.$store.state.user.location.guid }
        },
        { root: true }
      );
      for (let i=0;i<json.data.Kraals.length;i++){
       if (!json.data.Kraals[i].leftCoordinate){
        json.data.Kraals[i].leftCoordinate = new Models.GpsLatLon();
        json.data.Kraals[i].rightCoordinate = new Models.GpsLatLon();
        json.data.Kraals[i].leftCoordinate.lat = 0;
        json.data.Kraals[i].leftCoordinate.lon = 0;
        json.data.Kraals[i].rightCoordinate.lat = 0;
        json.data.Kraals[i].rightCoordinate.lon = 0;
       }
      }
      this.$store.commit("updateField", {
        path: "Kraals",
        value: json.data.Kraals
      });
    } catch (err) {
      console.log(err);
       this.$store.commit("popup/displayOk",  `Error: ${err.message}`);
    }
    if (push) this.$router.push({ path: "list/kraals" });
  }

  async lines(push: boolean = true) {
    try {
      this.kraals(false);
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: Models.gql.queries.lines,
          variables: { guid: this.$store.state.user.location.guid }
        },
        { root: true }
      );
      
      this.$store.commit("updateField", {
        path: "Lines",
        value: json.data.Lines
      });
    } catch (err) {
      console.log(err);
      this.$store.commit("popup/displayOk",  `Error: ${err.message}`);
    }
    if (push) this.$router.push({ path: "list/lines" });
  }

  async complexes(push: boolean = true) {
    try {
      this.lines(false);
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: Models.gql.queries.complexes,
          variables: { guid: this.$store.state.user.location.guid }
        },
        { root: true }
      );
      
      this.$store.commit("updateField", {
        path: "Complexes",
        value: json.data.Complexes
      });
    } catch (err) {
      console.log(err);
      this.$store.commit("popup/displayOk", 
            `Error: ${err.message}`
          );
    }
    if (push) this.$router.push({ path: "list/complexes" });
  }

  async tractors(push: boolean = true) {
    try {
      this.rations(false);
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: Models.gql.queries.tractors,
          variables: { guid: this.$store.state.user.location.guid }
        },
        { root: true }
      );
      
      this.$store.commit("updateField", {
        path: "Tractors",
        value: json.data.Tractors
      });
    } catch (err) {
      console.log(err);
      this.$store.commit("popup/displayOk",  `Error: ${err.message}`);
    }
    if (push) this.$router.push({ path: "list/tractors" });
  }

  async trailers(push: boolean = true) {
    try {
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: Models.gql.queries.trailers,
          variables: { guid: this.$store.state.user.location.guid }
        },
        { root: true }
      );
      
      this.$store.commit("updateField", {
        path: "Trailers",
        value: json.data.Trailers
      });
    } catch (err) {
      console.log(err);
      this.$store.commit("popup/displayOk",  `Error: ${err.message}`);
    }
    if (push) this.$router.push({ path: "list/trailers" });
  }

  async operators(push: boolean = true) {
    try {
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: Models.gql.queries.operators,
          variables: { guid: this.$store.state.user.location.guid }
        },
        { root: true }
      );
      
      this.$store.commit("updateField", {
        path: "Operators",
        value: json.data.Operators
      });
    } catch (err) {
      console.log(err);
      this.$store.commit("popup/displayOk",  `Error: ${err.message}`);
    }
    if (push) this.$router.push({ path: "list/operators" });
  }

  async loaders(push: boolean = true) {
    try {
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: Models.gql.queries.loaders,
          variables: { guid: this.$store.state.user.location.guid }
        },
        { root: true }
      );
      
      this.$store.commit("updateField", {
        path: "Loaders",
        value: json.data.Loaders
      });
    } catch (err) {
      console.log(err);
       this.$store.commit("popup/displayOk",  `Error: ${err.message}`);
    }
    if (push) this.$router.push({ path: "list/loaders" });
  }

  async rations(push: boolean = true) {
    try {
      this.materials(false);
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: Models.gql.queries.rations,
          variables: { guid: this.$store.state.user.location.guid }
        },
        { root: true }
      );
      
      this.$store.commit("updateField", {
        path: "Rations",
        value: json.data.Rations
      });
    } catch (err) {
      console.log(err);
       this.$store.commit("popup/displayOk",  `Error: ${err.message}`);
    }
    if (push) this.$router.push({ path: "list/rations" });
  }

  async materials(push: boolean = true) {
    try {
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: Models.gql.queries.materials,
          variables: { guid: this.$store.state.user.location.guid }
        },
        { root: true }
      );
      
      this.$store.commit("updateField", {
        path: "Materials",
        value: json.data.Materials
      });
    } catch (err) {
      console.log(err);
       this.$store.commit("popup/displayOk",  `Error: ${err.message}`);
    }
    if (push) this.$router.push({ path: "list/materials" });
  }
}
