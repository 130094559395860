


































































import { Component, Model, Vue } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import * as Models from "@gigalot/data-models";
import { v4 as uuidv4 } from "uuid";
import lodash from "lodash";

@Component({
  components: {
    DimssaButton
  }
})
export default class Notes extends Vue {
  upstreamMetadata = this.$store.getters["user/getUpstreamMetadata"]();
  noun = "";
  kraal!: Models.Kraal;
  tractor!: Models.Tractor;
  tableKey = 0;
  ration!: Models.Ration;
  dialog = false;
  condition = "";
  Kraals: Models.Kraal[] = [];
  Tractors: Models.Tractor[] = [];
  Rations: Models.Ration[] = [];
  selectedNotifications: Models.Notification[] = [];
  savedNotifications: Models.Notification[] = [];
  notice?: Models.Notification;
  notices: Models.Notification[] = [];
  headers = [
    {
      text: "Type",
      value: "conditions.fieldType",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Name",
      value: "conditions.fieldGuid",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Condition",
      value: "conditions.condition",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    }
  ];

  mounted() {
    this.Kraals = this.$store.getters["storage"]().Kraals as Models.Kraal[];
    this.Tractors = this.$store.getters["storage"]().Tractors as Models.Tractor[];
    this.Rations = this.$store.getters["storage"]().Rations as Models.Ration[];
    this.getData();
    this.getNotices();
  }
  async getData() {
    try {
      console.log("getData()");
      let metadata : Models.UpstreamMetadata = this.$store.getters["user/getUpstreamMetadata"]();
      let gql = `query($guid:String!,$uuid:String!){
        Notifications(guid:$guid, uuid:$uuid) {
          typename
          guid
          userId
          notifiedCount
          message
          conditions{
            typename
            guid
            fieldType
            fieldGuid
            condition
          }
        }
      }`;
      let json = await this.$store.dispatch("graphQl", {
        gql,
        variables: { guid: this.$store.state.user.location.guid, uuid: metadata.userId },
        url: "https://pi.gigalot.systems:7777/feeder"
      });

      console.log("Done Downloading");

      this.savedNotifications = json.data.Notifications;
    } catch (err) {
      console.log("getNotifications -> error: " + err);
    } finally {
     }
  }
  get themeClass(): string {
    return this.$store.state.lightDarkMode;
  }

  async getNotices(){
    this.notices = this.$store.getters["storage"]().activeNotices as Models.Notification[];
    setInterval(()=>{  this.notices = this.$store.getters["storage"]().activeNotices as Models.Notification[];},5000)
  
  }
  
  addNewNotification() {
    this.dialog = true;
  }

  async removeNotice(notice:Models.Notification){
    try {
        let json = await this.$store.dispatch(
          "graphQl",
          {
            gql: `mutation removeActiveNotice($guid: String!, $notice: NotificationInput!) {
            removeActiveNotice(guid: $guid, notice: $notice) 
            }`,
            variables: {
              guid: this.$store.state.user.location.guid,
              notice: notice
            }
          },
          { root: true }
        );
       lodash.remove(this.notices,{guid:notice.guid});
       let temp = this.notices;
       this.notices = [];
       this.notices = temp;
      } catch (err) {
        console.log(err);
   
        throw err;
      }
  }

  async removeNotification(notice: Models.Notification) {
  
  try {
        let json = await this.$store.dispatch(
          "graphQl",
          {
            gql: `mutation removeNotification($guid: String!, $notification: NotificationInput!) {
            removeNotification(guid: $guid, notification: $notification) 
            }`,
            variables: {
              guid: this.$store.state.user.location.guid,
              notification: notice
            }
          },
          { root: true }
        );
       
        lodash.remove(this.savedNotifications, {guid:notice.guid});
        this.rerender();
      } catch (err) {
        console.log(err);
        throw err;
      }
    this.selectedNotifications = [];
  }


  rerender() {
    this.tableKey += 1;
  }

  getNotificationDetails(item: Models.Notification) {
    switch (item.conditions.fieldType) {
      case "Kraal":
        let kraals = this.$store.getters["storage"]().Kraals as Models.Kraal[];
        let kraal = kraals.find((kr)=>{ return kr.guid === item.conditions.fieldGuid});
        return kraal?.kraalId;
        break;
      case "Tractor":
        let tractors = this.$store.getters["storage"]().Tractors as Models.Tractor[];
        let tractor = tractors.find((trac) => { return trac.guid === item.conditions.fieldGuid});
        return tractor?.registrationNumber;
        break;
      case "Ration":
        let rations = this.$store.getters["storage"]().Rations as Models.Ration[];
        let ration = rations.find((rat)=>{return rat.guid === item.conditions.fieldGuid});
        return ration?.name;
        break;
    }
  }

  saveNotification() {
    this.dialog = false;
    let field = "";
    switch (this.noun) {
      case "Kraal":
        field = this.kraal.guid;
        break;
      case "Ration":
        field = this.ration.guid;
        break;
      case "Tractor":
        field = this.tractor.guid;
    }

    let notice: Models.Notification = {
      typename: "Notification",
      guid: uuidv4(),
      metadata: this.upstreamMetadata,
      userId: this.upstreamMetadata.userId,
      conditions: { typename: "Condition", fieldType: this.noun, fieldGuid: field, condition: this.condition, guid: uuidv4() },
      message: ""
    };
    this.savedNotifications.push(notice);
    this.updateServer(notice);
    this.clearNoticePopup();
  }

  clearNoticePopup(){
    this.noun = "";
    this.kraal = new Models.Kraal();
    this.kraal.kraalId = "";
    this.ration = new Models.Ration();
    this.ration.name = "";
    this.tractor = new Models.Tractor();
    this.tractor.registrationNumber = "";
    this.condition = "";
  }

  async updateServer(object?: any) {
    console.log(object);
    if (object) {
      try {
        let json = await this.$store.dispatch(
          "graphQl",
          {
            gql: `mutation addNotification($guid: String!, $notification: NotificationInput!) {
            addNotification(guid: $guid, notification: $notification) 
            }`,
            variables: {
              guid: this.$store.state.user.location.guid,
              notification: object
            }
          },
          { root: true }
        );
        } catch (err) {
        console.log(err);
        throw err;
      }
    }
  }
}
