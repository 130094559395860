


















import { Component, Vue } from "vue-property-decorator";
//import * as Model from "@gigalot/data-models";
//const lodash = require("lodash");
import { EventBus } from '../eventBus';
import {
  DimssaButton,
  ButtonState
} from "@/components/shared/dimssa-button.vue";

@Component({
  components: {
    DimssaButton
  }
})
export default class BushDroppings extends Vue {
  name = "bushDroppings";
  loading = false;

   headers = [
    {
      text: "Time",
      value: "timestamp",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Tractor",
      value: "tractorID",
      align: "center",
      sortable: true,
      filterable: true,
      divider: false,
      group: false
    },
    {
      text: "Trailer",
      value: "trailerID",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Operator",
      value: "operatorID",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Ration",
      value: "rationFed",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Kraal",
      value: "kraalID",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Amount (kg)",
      value: "amountFed",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    }
  ];
  get themeClass(): string {
    return this.$store.state.lightDarkMode;
  }

  bushDroppings = [];
  urlws = "wss://pi.gigalot.systems:7778/api/ws";
  options = {};

  variables = {};
  operationName = null;

  mounted() {
    EventBus.$on('component-b-done', this.getBushDroppings);
    //this.$store.dispatch("user/addFirebaseCallback", this.getBushDroppings);
   }

  async getBushDroppings() {
    this.loading = true;
    try {
      console.log("getBushDroppings()");

      let gql = `query($guid:String!){
        BushDroppings(guid:$guid){
          timestamp
          latitude
          longitude
          amountFed
          kraalID
          operatorID
          tractorID
          trailerID
          rationFed
          isBushDrop
        }
      }`;
      let json = await this.$store.dispatch("graphQl", { gql, variables:{guid:this.$store.state.user.location.guid }, url: "https://pi.gigalot.systems:7777/feeder" });
      console.log("Done Downloading");
      this.bushDroppings = json.data.BushDroppings;
      this.loading = false;
    } catch (err) {
      console.log("getBushDroppings -> error: " + err);
    }
    EventBus.$emit('component-c-done');
  }
}
