


































































































































import { Component, Vue } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import lodash from "lodash";
import * as Models from "@gigalot/data-models";
import router from "../../router";

@Component({
  components: {
    DimssaButton
  }
})
export default class RationPlan extends Vue {
  loading = false;
  changed = false;
  time = "";
  selected = [];
  strategy = [];
  rations = [];
  tableKey = 0;
  rationPlan: Models.RationPlan = new Models.RationPlan();
  cribDeliverySequence: Models.CribDeliverySequenceItem[] = [];
  selectedRation: Models.RationPlanItem[] = [];
  swatches = [
    ["#FF0000", "#AA0000", "#550000"],
    ["#FFFF00", "#AAAA00", "#555500"],
    ["#00FF00", "#00AA00", "#005500"],
    ["#00FFFF", "#00AAAA", "#005555"],
    ["#0000FF", "#0000AA", "#000055"]
  ];
  get themeClass(): string {
    return this.$store.state.lightDarkMode;
  }
  headersRationPlan = [
    {
      text: "Order",
      value: "order",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Ration",
      value: "ration",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Days on Feed",
      value: "defaultDays",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Priority",
      value: "priority",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Variable",
      value: "variable",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Colour",
      value: "colour",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    }
  ];

  mounted() {
    this.$store.dispatch("user/addFirebaseCallback", this.rationPlandata);
    this.$store.dispatch("user/addFirebaseCallback", this.getRations);
  }

  get moment() {
    return this.$store.state.moment;
  }

  forceRerender() {
    this.tableKey += 1;
  }

  rationPlandata() {
    this.rationPlan = this.$store.getters["storage"]().rationPlan as Models.RationPlan;
  }

  async getRations() {
    this.loading = true;
    try {
      console.log("getData()");
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: Models.gql.queries.rations,
          variables: { guid: this.$store.state.user.location.guid }
        },
        { root: true }
      );
      console.log("Done Downloading");
      this.rations = json.data.Rations;
    } catch (err) {
      console.log("getCribPlans -> error: " + err);
    } finally {
      this.loading = false;
    }
  }

  async getData() {
    this.loading = true;
    try {
      console.log("getData()");

      let gql = `query($guid:String!){
         RationPlan(guid:$guid) {
          typename
          guid
          creationDate
          name
          rationItems{
            typename
            guid
            ration
            defaultDays
            colour
            order
            variable
            priority
          }
        }

        
      }`;
      let json = await this.$store.dispatch("graphQl", { gql,variables:{guid:this.$store.state.user.location.guid }, url: "https://pi.gigalot.systems:7777/feeder" });

      console.log("Done Downloading");
      
    } catch (err) {
      console.log("getCribPlans -> error: " + err);
    } finally {
      this.loading = false;
    }
  }

  moveRationDown() {
    this.changed = true;
    if (this.selectedRation.length > 0) {
      let nextItem = lodash.find(this.rationPlan.rationItems, { order: this.selectedRation[0].order + 1 });
      if (nextItem) {
        nextItem.order = nextItem.order - 1;
      }
      if (this.selectedRation[0].order < this.rationPlan.rationItems.length) this.selectedRation[0].order = this.selectedRation[0].order + 1;
    }
  }

  moveRationUp() {
    this.changed = true;
    if (this.selectedRation.length > 0) {
      let prevItem = lodash.find(this.rationPlan.rationItems, { order: this.selectedRation[0].order - 1 });
      if (prevItem) {
        prevItem.order = prevItem.order + 1;
      }
      if (this.selectedRation[0].order > 1) this.selectedRation[0].order = this.selectedRation[0].order - 1;
    }
  }

  addRation() {
    this.changed = true;
    let item: Models.RationPlanItem = new Models.RationPlanItem();
    item.colour = "Grey";
    item.order = this.rationPlan.rationItems.length + 1;
    this.rationPlan.rationItems.push(item);
  }

  removeRation() {
    this.changed = true;
    let order = this.selectedRation[0].order;
    lodash.remove(this.rationPlan.rationItems, { guid: this.selectedRation[0].guid });
    for (let i = order - 1; i < this.rationPlan.rationItems.length; i++) {
      this.rationPlan.rationItems[i].order = this.rationPlan.rationItems[i].order - 1;
    }
    this.forceRerender();
  }

  async save(item: any) {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure you want to save?",
      yesAction: async () => {
 //update server with changes
    if (!item.creationDate) {
      item.creationDate = Date.now();
    }
    for (let i = 0; i < item.rationItems.length; i++) {
      if (item.rationItems[i].rationGuid == undefined) {
        item.rationItems[i].rationGuid = item.rationItems[i].ration.guid;
      }

      if (typeof item.rationItems[i].ration != "string") {
        item.rationItems[i].ration = item.rationItems[i].ration.name;
      }
    }

    console.log("Save");
    await this.updateServer(item);
     this.$router.go(-1);
      }
      });
  }

   cancel() {

 if (this.changed){
      this.$store.commit("popup/displayYesNo", {
      message: "Discard changes?",
      yesAction: () => {
        this.$router.go(-1);
      }
    });
    }
    else {
      this.$router.go(-1);
    }
  }

  close() {
    console.log("Close");
  }

  async updateServer(object?: string) {
    console.log(object);
    if (object) {
      try {
        let json = await this.$store.dispatch(
          "graphQl",
          {
            gql: `mutation rationPlan($rationPlan: RationPlanInput!, $guid: String!) {
            rationPlan(rationPlan: $rationPlan, guid: $guid) 
            }`,
            variables: {
             guid: this.$store.state.user.location.guid,
              rationPlan: object,
            }
          },
          { root: true }
        );
       
      } catch (err) {
        console.log(err);
        throw err;
      }
    }
  }
}
