

































































































































































































































































































































































// @ is an alias to /src
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import { Component, Vue } from "vue-property-decorator";
import * as Model from "@gigalot/data-models";
import { v4 as uuidv4 } from "uuid";
import lodash from "lodash";

@Component({
  components: { DimssaButton },
})
export default class FeedPlan extends Vue {
  manualEntry = false;
  FPdialog = false;
  notifications = false;
  sound = true;
  widgets = false;
  MoveDialog = false;
  name = "feedPlan";
  loading = true;
  dialog = false;
  rationPlan: Model.RationPlan = new Model.RationPlan();
  selectedRationPlan?: Model.RationPlan = new Model.RationPlan();
  rationPlans: Model.RationPlan[] = [];
  feedPlans: Model.FeedingPlan = new Model.FeedingPlan();
  selectedFeedPlan: Model.FeedingPlanItem[] = [];
  selected?: Model.FeedingPlanItem;
  toFeedPlanItem?: Model.FeedingPlanItem;
  customFeeders: Model.CustomFeeder[] = [];
  page = 1;
  snack = false;
  snackColor = "";
  snackText = "";
  errorText = "";
  errorDialog = false;
  tableKey = 0;
  search = "";
  date = "2012-01-01";
  genders = ["Male", "Female", "Mix"];
  headers = [
    {
      text: "Kraal",
      value: "kraalId",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
      width: 90,
    },
    {
      text: "Ration",
      value: "currentRation",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
      width: 100,
    },
    {
      text: "Kraal Days",
      value: "projectedDaysInFeedlot",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
      width: 100,
    },
    {
      text: "Animal #",
      value: "numberAnimals",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
      width: 90,
    },
    {
      text: "Owner",
      value: "owner",
      align: "center",
      sortable: true,
      filterable: false,
      divider: true,
      width: 100
    },
    {
      text: "Incoming Week",
      value: "incomingWeek",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
      width: 120,
    },
    {
      text: "Gender",
      value: "gender",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
      width: 120,
    },
    {
      text: "Kraal Mass",
      value: "kraalAverageMass",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
      width: 100,
    },
    {
      text: "Kraal Date",
      value: "kraalDate",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
      width: 110,
    },
    {
      text: "2nd Implant Date",
      value: "implant2Date",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
      width: 130,
    },
    {
      text: "Slaughter Date",
      value: "proposedSlaughterDate",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
      width: 120,
    },
    {
      text: "Ration Plan",
      value: "rationPlan",
      width: 520,
    },
    {
      text: "Notes",
      value: "notes",
      align: "center",
      sortable: false,
      filterable: false,
      divider: true,
     
    },
  ];

  rule1 = [
    (v: any) => {
      if (v > 500) return "Max 500 days!";
      if (v < 20) return "Min 20 days!";
      return v ? true : "Must contain value";
    },
  ];

  rule2 = [
    (v: any) => {
      if (v < 0) return "Animal must be positive";
      return v ? true : "Must contain value";
    },
  ];

  mounted() {
    this.$store.dispatch("user/addFirebaseCallback", this.getFeedPlans);
    this.$store.dispatch("user/addFirebaseCallback", this.getCustomFeeders);
    
  }
  get today() {
    return Date.toString();
  }

  forceRerender() {
    let pageHolder = this.page;
    console.log(pageHolder);
    this.tableKey += 1;
    this.page = pageHolder;
    console.log(pageHolder);
  }

  chooseRationPlan(item: any) {
    this.dialog = true;
    let rationPlan = lodash.find(this.rationPlans, { guid: item.rationPlanGuid });
    if (rationPlan) this.selectedRationPlan = rationPlan;
    this.selected = item;
  }

  getRationName(guid: string) {
    let rationplan = lodash.find(this.rationPlans, { guid: guid });
    if (rationplan) return rationplan.name;
  }

  displayMoveDialog(){
    this.MoveDialog = true;
  }
  lookupOwner(owner:string){
    //owner could be guid or string.. Do lookup of guid. If not found just return string
    let ownerslookup = lodash.find(this.customFeeders,{guid:owner});
    if (ownerslookup){
    return ownerslookup.name;
    }
    else return owner;
  }
  async moveSelectedKraal() {
    //display confirmation dialog
    let toItem = -1;
    if (this.toFeedPlanItem){
    toItem = lodash.findIndex(this.feedPlans.FeedingPlanItems, {kraalId : this.toFeedPlanItem.kraalId});
    }

if (toItem > -1){
 //copy existing kraal parameters to new kraal moved to... ie the plan and ration etc.
     Object.assign(this.memento, this.selectedFeedPlan[0]);
     if (this.memento){
       let ex_guid = this.feedPlans.FeedingPlanItems[toItem].guid;
       let kraalGuid = this.feedPlans.FeedingPlanItems[toItem].kraalGuid;
       let kraalId = this.feedPlans.FeedingPlanItems[toItem].kraalId;
       let sequence = this.feedPlans.FeedingPlanItems[toItem].sequence;

       this.feedPlans.FeedingPlanItems[toItem] = lodash.clone(this.memento);

       this.feedPlans.FeedingPlanItems[toItem].guid = ex_guid;
       this.feedPlans.FeedingPlanItems[toItem].kraalGuid = kraalGuid;
       this.feedPlans.FeedingPlanItems[toItem].kraalId = kraalId;
       this.feedPlans.FeedingPlanItems[toItem].sequence = sequence;

       this.selectedFeedPlan[0].numberAnimals = 0;
       this.selectedFeedPlan[0].owner = "";
       this.selectedFeedPlan[0].kraalAverageMass = 0;

        //Move cribscore to new kraal also
        let json = await this.$store.dispatch(
          "graphQl",
          {
            gql: `mutation moveCribScore($guid: String!, $fromKraal: String!, $toKraal: String!) {
            moveCribScore(guid:$guid, fromKraal: $fromKraal,toKraal: $toKraal) 
          }`,
            variables: { guid: this.$store.state.user.location.guid, fromKraal: this.selectedFeedPlan[0].kraalId, toKraal: this.feedPlans.FeedingPlanItems[toItem].kraalId },
          },
          { root: true }
        );

       console.log(json);
     }

}
     this.forceRerender();
     this.save(this.selectedFeedPlan[0])
     this.save(this.feedPlans.FeedingPlanItems[toItem])
     this.MoveDialog = false;
}

  clearSelectedKraal() {
    this.$store.commit("popup/displayYesNo", {
      message: `Are you sure you want to clear the feed plan for kraal ${this.selectedFeedPlan[0].kraalId}`,
      yesAction: () => {
       this.selectedFeedPlan[0].numberAnimals = 0;
       this.selectedFeedPlan[0].owner = "";
       this.selectedFeedPlan[0].kraalAverageMass = 0;
       this.save(this.selectedFeedPlan[0])
      },
    });
    //display confirmation dialog

    //make kraal clear? I don't know what that means to the system
  }

  editSelectedKraal() {
    this.open(this.selectedFeedPlan[0]);
    this.FPdialog = true;
    //show full page edit screen
  }

  getDays(kraalDate: string) {
    let kDate = this.moment(kraalDate);
    let nDate = this.moment(Date.now());
    let days = nDate.diff(kDate, "days");
    return ++days;
  }

  getWeekNumber(d: number) {
    let e = new Date(d);
    let f = e.getTime();
    let dayNum = e.getUTCDay() || 7;
    e.setUTCDate(e.getUTCDate() + 4 - dayNum);
    let yearStart = new Date(Date.UTC(e.getUTCFullYear(), 0, 1));
    return Math.ceil(((e.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
  }

  updateDates(plan: Model.FeedingPlanItem, adjustedLatterRations: boolean = false) {
    let variable = false;
    let subtractedDays = 0;
    let addedDays = 0;
    let totalDays = 0;
    //1 Add up all days of all rations to find how many days left for variable ration

    if (adjustedLatterRations) {
      totalDays = 0;
      for (let i = 0; i < plan.rationPlan.length; i++) {
        totalDays = totalDays + plan.rationPlan[i].days;
      }

      //adjust projected Days
      plan.projectedDaysInFeedlot = totalDays;
    }
    totalDays = 0;
    for (let i = 0; i < plan.rationPlan.length; i++) {
      if (!plan.rationPlan[i].variable) totalDays = totalDays + plan.rationPlan[i].days;
    }
    plan.proposedSlaughterDate = this.addDays(plan.projectedDaysInFeedlot, plan.kraalDate).getTime();
    if (plan.kraalDate) {
      plan.incomingWeek = this.moment(plan.kraalDate).week();

      //4 Adjust implant date
      plan.implant2Date = new Date(plan.kraalDate + 1000 * 60 * 60 * 24 * 55).getTime(); //TODO: Implant days is magic number 55
    }

    //  addedDays = 0;

    // 2 Adjust variable days to take up remaining days
    for (let i = 0; i < plan.rationPlan.length; i++) {
      if (plan.rationPlan[i].variable === true) {
        variable = true;
        plan.rationPlan[i].days = plan.projectedDaysInFeedlot - totalDays;
      }

      //Move all dates on by adding previous added days
      if (plan.kraalDate) plan.rationPlan[i].startDate = this.addDays(addedDays, plan.kraalDate).getTime();
      addedDays += plan.rationPlan[i].days;
    }

    //3 Moves the current ration plan to the one that has been activated after this date.
    for (let i = 0; i < plan.rationPlan.length; i++) {
      if (plan.rationPlan[i].startDate < Date.now()) {
        plan.currentRation = plan.rationPlan[i];
      }
    }
  }
  //helpers

  getKraalType(guid: string) {
    let kraals = this.$store.getters[`storage`]().Kraals as Model.Kraal[];
    let kraal = lodash.find(kraals, { guid: guid });
    if (kraal) return kraal.kraalType;
  }

  addDaysDate(date: Date, days: number) {
    date = new Date(date.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  addDays(days: number, date?: number) {
    var nuDate = new Date();
    if (date) {
      nuDate = new Date(date.valueOf());
      nuDate.setDate(nuDate.getDate() + days);
    }
    return nuDate;
  }
  save(item?: any) {
    if (item) {
      this.open(item);
    }
    //update server with changes

    let thisguid = this.open_guid;
    if (!thisguid?.owner){
      thisguid.owner = "";
    }
    this.updateDates(thisguid, false);
    this.updateServer(thisguid);
  }

  saveRationSpecific() {
    //update server with changes
    let thisguid = this.open_guid;
    this.updateDates(thisguid, true);
    this.updateServer(thisguid);
  }

  cancelRationPlanChange(selectedRationPlan: any) {
this.dialog = false;
 let feedplanitem = lodash.findIndex(this.feedPlans.FeedingPlanItems,{ guid: selectedRationPlan.guid});
    if (feedplanitem> -1){
     this.feedPlans.FeedingPlanItems[feedplanitem] = lodash.cloneDeep(this.memento);
    }
    this.selectedFeedPlan[0] = lodash.cloneDeep(this.memento);
    this.forceRerender();
  }

  acceptRationPlanChange(selectedRationPlan: any) {
    if (this.selected) {
      //1
      let rationPlan: Model.RationScheduleItem[] = [];
      let addedDays = 0;
      //generate  ration schedule items
      selectedRationPlan.rationItems.forEach((plannedRation: any) => {
        let rationPlanItem: Model.RationScheduleItem = {
          days: plannedRation.defaultDays,
          guid: Date.now() + "_" + uuidv4(),
          ration: plannedRation.ration,
          rationGuid: plannedRation.rationGuid,
          startDate: this.addDaysDate(new Date(), addedDays).getTime(),
          variable: plannedRation.variable,
          colour: plannedRation.colour,
        };
        rationPlan.push(rationPlanItem);
        addedDays += plannedRation.defaultDays;
      });

      this.selected.rationPlanGuid = selectedRationPlan.guid;
      this.selected.rationPlan = rationPlan;
      // this.updateDates(this.selected);
      this.open_guid = this.selected;

      this.save();
    }
    this.dialog = false;
  }

  saveRation(thisguid: any) {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure you want to change the ration?",
      yesAction: () => {
        this.updateServer(thisguid);
      },
      noAction: () => {
        this.cancel(thisguid);
      }
    });
  }
  memento : any = {};
  open_guid?: any = {};

  cancel(item: any) {
    if (item){
 let feedplanitem = lodash.findIndex(this.feedPlans.FeedingPlanItems,{ guid: item.guid});
    if (feedplanitem> -1){
     this.feedPlans.FeedingPlanItems[feedplanitem] = lodash.cloneDeep(this.memento);
    }
    this.selectedFeedPlan[0] = lodash.cloneDeep(this.memento);
    //restore memento cause canceled
    this.forceRerender();
    this.open_guid = {};
    this.snack = true;
    this.snackColor = "blue lighten-1";
    this.snackText = "Canceled";
    }
   
  }
  open(guid?: any) {
    Object.assign(this.memento, guid);
    this.open_guid = guid;
  }
  close() {
    this.open_guid = {};
  }

  getRations(feedPlanItem: Model.FeedingPlanItem): Model.RationScheduleItem[] {
    return feedPlanItem.rationPlan;
  }

  getColor(ration: string, feedPlanItem?: any) {
    if (feedPlanItem) {
      let index = this.rationPlans.findIndex((r) => {
        return r.guid === feedPlanItem.rationPlanGuid;
      });
      if (index > -1) {
        let index2 = this.rationPlans[index].rationItems.findIndex((s) => {
          return s.ration === ration;
        });
        if (index2 > -1) return this.rationPlans[index].rationItems[index2].colour;
        else return "";
      } else return "";
    } else {
      let index = this.rationPlan.rationItems.findIndex((r) => {
        return r.ration === ration;
      });
      if (index > -1) {
        return this.rationPlan.rationItems[index].colour;
      } else return "";
    }
  }

  getDate(date: any) {
    return new Date(date + 7200000).toISOString().substr(0, 10);
   }

  setDate(date: any) {
    return this.moment(date).valueOf() + 7200000; //TODO: GMT+2 = 7200000
  }
  get moment() {
    return this.$store.state.moment;
  }

  async getCustomFeeders(){
    try{
        let gql = Model.gql.queries.customFeeders;
        let json = await this.$store.dispatch("graphQl", { gql, variables: { guid: this.$store.state.user.location.guid } });
        this.customFeeders = json.data.customFeeders;
      } catch (err) {
      console.log("getCustomFeeders -> error: " + err);
      this.errorDialog = true;
      this.errorText = `Error downloading from server: ${err}`;
    }
  }
  get themeClass(): string {
    return this.$store.state.lightDarkMode;
  }

  async getFeedPlans() {
    try {
      
      console.log("FeedPlans()");
      this.loading = true;
      let gql = `query($guid:String!){
        FeedPlan(guid:$guid){
          typename
          guid
          FeedingPlanItems {
            guid
            kraalId
            kraalGuid
            currentRation { guid
            ration 
            colour
            rationGuid
            startDate
            days
            variable
            }
            sequence
            projectedDaysInFeedlot
            numberAnimals
            owner
            incomingWeek
            gender
            kraalAverageMass
            kraalAverageMassFloat
            kraalDate
            implant2Date
            proposedSlaughterDate
            rationPlanGuid
            time
            notes
            rationPlan{
              guid
              ration
              rationGuid
              startDate
              days
              variable
              colour
            }
          }    
        }
          RationPlans(guid:$guid) {
          typename
          guid
          creationDate
          name
          rationItems{
            typename
            guid
            ration
            rationGuid
            defaultDays
            colour
            order
            variable
          }
        }
           RationPlan(guid:$guid) {
          typename
          guid
          creationDate
          rationItems{
            typename
            guid
            ration
            rationGuid
            defaultDays
            colour
            order
            variable
          }
        }
      }`;

      let json = await this.$store.dispatch("graphQl", { gql, variables: { guid: this.$store.state.user.location.guid } });
      this.feedPlans = json.data.FeedPlan;
      this.rationPlan = json.data.RationPlan;
      this.rationPlans = json.data.RationPlans;
    
    } catch (err) {
      console.log("getFeedPlans -> error: " + err);
      this.errorDialog = true;
      this.errorText = `Error downloading from server: ${err}`;
    }
    this.loading = false;
  }

  async updateServer(editedItem?: any) {
    if (editedItem) {
      try {
        console.log("updateServer()");
        editedItem._send = "sending";
        this.forceRerender();
        let copy = lodash.cloneDeep(editedItem);
        delete copy._send;
        let guid_string = JSON.stringify(editedItem);
        let json = await this.$store.dispatch(
          "graphQl",
          {
            gql: `mutation AddFeedingPlan($guid: String!, $input: FeedingPlanItemInput!) {
            addFeedPlan(guid:$guid, input: $input) 
          }`,
            variables: { guid: this.$store.state.user.location.guid, input: copy },
          },
          { root: true }
        );

        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Data saved";
        editedItem._send = "receivedByServer";
        this.forceRerender();
      } catch (err) {
        console.log(err);
        editedItem._send = "failedToSend";
        this.forceRerender();
        this.errorDialog = true;
        this.errorText = `Error saving to server: ${err} ${editedItem.kraalId}`;

        throw err;
      }
    }
  }
}
